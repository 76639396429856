<template>
    <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop hide-footer
        :title="itemData.title">

        <b-card style="height: 100%;" class="text-center">
            <vue-pdf-app v-if="itemData.type === 'pdf'" style="height: calc(var(--vh, 1vh)*100 - 14rem); width: 100%;"
                :pdf="pdf" :config="config"></vue-pdf-app>
                <div v-else-if="itemData.type === 'docx'" class="iframe-container">
            <iframe 
            style="height: calc(var(--vh, 1vh)*100 - 14rem + 21px);"
                :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + itemData.url"
                width='100%' height='100%' frameborder='0'>This is an embedded <a target='_blank'
                    href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank'
                    href='http://office.com/webapps'>Office Online</a>.</iframe></div>
            <img v-else :src="'data:image/' + itemData.type + ';base64,' + itemData.base_64"
                class="img-fluid img-no-action" alt="Document image">
        </b-card>

    </b-modal>
</template>

<script>
import VuePdfApp from 'vue-pdf-app';
import "vue-pdf-app/dist/icons/main.css";

export default {
    components: {
        VuePdfApp
    },

    props: ["itemData", "show"],

    created() {
        if (this.itemData.base_64 != null) {
            if (this.itemData.type === 'pdf') {
                var characters = atob(this.itemData.base_64);
                var array = new Uint8Array(characters.length);

                for (var i = 0; i < characters.length; i++) {
                    array[i] = characters.charCodeAt(i);
                }

                this.pdf = array.buffer;
            }
        }

    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) },
        }
    },

    watch: {
    },

    data() {
        return {
            pdf: null,
            config: {
                toolbar: false
            },
        }
    },

    methods: {}
};
</script>

<style></style>
<style lang="scss" scope>
.iframe-container {
    position: relative;
    height: calc(var(--vh, 1vh)*100 - 14rem);
    width: calc(100% - 4px);
    overflow: hidden;
}

.iframe-container iframe {
    position: relative;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 104px);
}

.pdfContainer {
    text-align: center;
}

.img-no-action {
    pointer-events: none;
}

.viewPDF {
    border: 1px solid #71e6ee;
    margin-left: auto;
    margin-right: auto;
    min-width: 400px;
    max-width: 500px;
}

.paginationContainer {
    margin-top: 30px;
}

.printButton {
    float: right;
}

.content-area-wrapper {
    height: calc(var(--vh, 1vh)*100 - 14rem);
    display: -webkit-box;
    display: flex;
    position: relative;
    overflow: hidden;
}

.pdf-app .pdfViewer .page {
    border: none !important;
    margin: 6px auto 12px auto !important;
}
</style>

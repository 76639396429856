<template>
    <div v-if="(ids_qualification_const_type && ids_qualification_const_type.length > 0) || type == 'add-fw'" :id="'popover_' + _uid" tabindex="0" class="app-local-dot"
        style="margin-top:2px; font-size:14px; font-weight:600;">
        <span class="ml-auto" v-bind:class="{
        'dot-white': ((ids_qualification_const_type && ids_qualification_const_type.length > 0) && idPersonDataMain == null && type != 'add-fw'),
        'dot-orange': dotColor == 1,
        'dot-green': dotColor == 2,
        'dot-red': dotColor == 0
    }"> </span>
        <b-popover :target="'popover_' + _uid" triggers="hover" no-fade custom-class="app-local-popover w-25"
            @show="getQualifications">
            <template #title>
                {{ trans('kwalifications', 283) }}
                <b-spinner v-if="loading" style="width:14px; height:14px; margin-bottom:3px; margin-left:3px;"
                    small></b-spinner>
            </template>

            <div class="mt-2">
                <span class="app-local-span" v-if="!loading && qualifications.length == 0">{{ trans('no-qualifications',
        292) }}</span>
                <span v-for="(item, index) in qualifications" :key="index" class="app-local-span"
                    v-html="index == qualifications.length - 1 ? (item.mark == 1 ? ('<b>' + item.qualification_name + '</b>') : item.qualification_name) : (item.mark == 1 ? ('<b>' + item.qualification_name + '</b>, ') : (item.qualification_name + ', '))"></span>
            </div>

        </b-popover>
    </div>
</template>
<script>

import axios from "@axios";
export default {
    components: {

    },

    props: {
        dotColor: {
            type: Number,
            default: null
        },
        idPersonDataMain: {
            type: Number,
            default: null
        },
        idsShiftDataMain: {
            type: Array,
            default: null
        },
        ids_qualification_const_type: {
            type: Array,
            default: null
        },
        type: {
            type: String,
            default: null
        },
        queryType: {
            type: Number,
            default: 1 // 1: shifts, 2: assignments
        }
    },

    data() {
        return {
            loading: true,
            qualifications: [],
        }
    },

    methods: {
        getQualifications() {
            this.qualifications = [];
            this.loading = true;
            if (this.queryType === 1) {
                axios
                    .post("shifts/getQualifications", {
                        ids_shift_data_main: JSON.stringify(this.idsShiftDataMain),
                        id_person_data_main: this.idPersonDataMain
                    })
                    .then((res) => {
                        this.qualifications = res.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
            } else if (this.queryType === 2) {
                axios
                    .post("assignments/getQualifications", {
                        id_assignment_data_main: JSON.stringify(this.idsShiftDataMain),
                        id_person_data_main: this.idPersonDataMain
                    })
                    .then((res) => {
                        this.qualifications = res.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
<style scoped>
.app-local-dot {
    text-align: center;
}

.app-local-span {
    font-size: 12px;
    font-weight: 400;
}
</style>